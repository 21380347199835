<template>
  <section class="slider">
    <ul class="slider--home">
      <li
        class="slider--home__item item--home"
        @mouseover="activeLink = slides.id"
        v-on:click.prevent="activeLink = slides.id"
        :class="{ active: slides.id == activeLink }"
        v-for="(slides, index) in slider"
        :key="slides.id"
      >
        <v-lazy-image
          :src="url + slides.image"
          width="1104"
          height="918"
          rel="preload"
          :alt="'slider-' + index"
        />
        <div class="item--home__wrap">
          <div class="item--home__inner">
            <div class="item--home__title">
              <v-lazy-image
                :src="iconUrl[index]"
                width="60"
                height="60"
                rel="preload"
                :alt="'slider-' + index"
              />
              <h2 class="item--home__title--text">
                <pre>{{ slides.name }}</pre>
              </h2>
            </div>
            <div class="item--home__descr">
              <div v-html="slides.description"></div>
              <router-link
                :to="{ path: slides.slug }"
                class="item--home__descr--link"
              >
                {{ t("more_details") }}
              </router-link>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <a
      href="#"
      v-scroll-to="'#batch-solutions'"
      v-if="show === true"
      class="slider--arrow"
    >
      down
      <span class="slider--arrow__chevron"></span>
      <span class="slider--arrow__chevron"></span>
      <span class="slider--arrow__chevron"></span>
    </a>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import VLazyImage from "v-lazy-image";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  components: {
    VLazyImage,
  },
  props: {
    url: {
      type: String,
      default: process.env.VUE_APP_API,
    },
    slider: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      window: {
        width: 0,
      },
      show: true,
      activeLink: 3,
    };
  },
  computed: {
    iconUrl() {
      return [
        require("@/assets/images/slider--home-1.svg"),
        require("@/assets/images/slider--home-2.svg"),
        require("@/assets/images/slider--home-3.svg"),
        require("@/assets/images/slider--home-4.svg"),
        require("@/assets/images/slider--home-5.svg"),
        require("@/assets/images/slider--home-6.svg"),
      ];
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;

      if (this.window.width >= 576) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";

$baseTr: 0.6rem;

.slider {
  position: relative;
  z-index: 30;
  @include property("margin-bottom", 170, 40);

  &--arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    position: absolute;
    left: 50%;
    bottom: 60px;
    transform: translateX(-50%);
    z-index: 10;
    font-size: 0;
    cursor: pointer;

    &__chevron {
      position: absolute;
      width: 100%;
      height: 46px;
      opacity: 0;
      transform: scale(0.3);
      animation: move-chevron 3s ease-out infinite;
      margin-top: -80px;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: url("~@/assets/images/arrow-down.svg") no-repeat;
        background-size: 100% 100%;
      }

      &:first-child {
        animation: move-chevron 3s ease-out 1s infinite;
      }

      &:nth-child(2) {
        animation: move-chevron 3s ease-out 2s infinite;
      }
    }
  }

  &--home {
    display: flex;
    overflow: hidden;

    @media (min-width: 576px) {
      height: calc(100vh - 105px);
    }

    @media (max-width: 575.98px) {
      flex-direction: column;
    }
  }
}

@keyframes move-chevron {
  33.3% {
    opacity: 0.6;
    transform: translateY($baseTr * 3.8);
  }

  66.6% {
    opacity: 1;
    transform: translateY($baseTr * 5.2);
  }

  100% {
    opacity: 0;
    transform: translateY($baseTr * 8) scale(0.5);
  }
}

.item--home {
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 11%;
  @include property("min-width", 208, 180, true, 1670, 1024);
  @include property("padding", 24, 15, true, 1670, 1024);
  transition: width 0.3s linear;

  @media (max-width: 1023.98px) {
    width: 60px;
    min-width: auto;
  }

  @media (max-width: 575.98px) {
    width: 100%;
    padding: 15px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 1;
  }

  &.active {
    width: calc(100% - (11% * 5));

    @media (max-width: 1023.98px) {
      width: calc(100% - (60px * 5));
    }

    @media (max-width: 575.98px) {
      width: 100%;
      padding: 15px !important;
    }
  }

  &:not(.active) {
    cursor: pointer;
  }

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: 2;
    opacity: 0.25;
  }

  &__wrap {
    position: relative;
    z-index: 5;
    width: 100%;

    @media (min-width: 1024px) {
      height: 450px;
    }

    &::before {
      content: "";
      position: absolute;
      @include property("top", 90, 10, true, 1670, 1024);
      @include property("left", -24, -15, true, 1670, 1024);
      width: 3px;
      height: 54px;
      background: #f9b80e;

      @media (max-width: 1023.98px) {
        top: 50%;
        transform: translateY(-50%);
      }

      @media (max-width: 575.98px) {
        left: -15px;
      }
    }
  }

  &__inner {
    max-width: 640px;
    margin: 0 auto;
    overflow: hidden;
  }

  &__title {
    display: flex;
    flex-wrap: wrap;

    &--text {
      position: relative;
      display: flex;
      align-items: center;
      color: #f9b80e;
      text-transform: uppercase;
      font-weight: 700;
      line-height: calc(25 / 16);
      letter-spacing: 0.8px;
      width: 100%;

      @media (max-width: 1200px) {
        font-size: 15px;
        line-height: calc(20 / 15);
        letter-spacing: 0.75px;
      }

      @media (min-width: 576px) and (max-width: 1023.98px) {
        display: none;
      }

      @media (max-width: 575.98px) {
        width: calc(100% - 45px);
        font-size: 24px;

        pre {
          white-space: initial;
        }
      }

      .active & {
        font-size: 32px;
        @include property("font-size", 32, 18, true, 1670, 1024);
        line-height: 1.3;
        letter-spacing: 1.6px;
        width: calc(100% - 90px);
        transition: font-size 0.3s linear;

        @media (max-width: 1200px) {
          letter-spacing: 1.5px;
        }

        @media (max-width: 1023.98px) {
          display: block;
          font-size: 30px;
        }

        @media (max-width: 767.98px) {
          font-size: 24px;
        }

        @media (max-width: 575.98px) {
          width: calc(100% - 45px);
        }

        pre {
          white-space: initial;
        }
      }
    }

    img {
      flex: 0 0 auto;
      max-width: max-content;
      @include property("height", 60, 30, true, 1670, 1024);
      @include property("margin-bottom", 30, 10, true, 1670, 1024);
      margin-right: 30px;

      @media (max-width: 575.98px) {
        margin-right: 15px;
      }

      .item--home:not(.active) & {
        @media (min-width: 576px) and (max-width: 1023.98px) {
          margin: 0 auto;
        }
      }

      .active & {
        margin-bottom: 0;

        @media (max-width: 767.98px) {
          margin-right: 15px;
        }
      }
    }
  }

  &__descr {
    font-size: 18px;
    line-height: calc(30 / 18);
    color: #e1e1e1;
    @include property("margin-left", 90, 0, true, 1670, 1024);
    @include property("margin-top", 45, 15, true, 1670, 768);

    @media (min-width: 576px) {
      display: none;
    }

    @media (max-width: 575.98px) {
      margin-top: 5px;
    }

    .active & {
      display: block;
    }

    &--link {
      line-height: calc(30 / 16);
      letter-spacing: 2.4px;
      color: #f9b80e;
    }

    > div {
      max-width: 550px;
      max-height: 90%;
      @include property("margin-bottom", 34, 10, true, 1670, 768);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -moz-box;
      -moz-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 9;
      -webkit-box-orient: vertical;
      line-clamp: 9;
      box-orient: vertical;

      @media (max-width: 575.98px) {
        display: none;
      }

      @media (max-height: 699.98px) {
        display: none;
      }
    }
  }
}
</style>
